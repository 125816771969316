<template>
  <div class="balance-card">
    <div>
      <div class="bb-intro-top">
        <div>
          <i class="fas fa-crown"></i>
          <b class="b-icon-title"> Annual Pro Accounts for Students</b>
        </div>
        <div v-if="isAdmin">
          <el-tooltip effect="dark" content="Edit Pro Accounts" placement="top">
            <el-button type="primary" size="small" @click="$emit('editPro')">
              <i class="fa fa-edit"></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div class="b-info">
        <h3 class="balance-price">{{ point }}</h3>
        <span class="balance-name" v-if="point > 0">
          {{ point > 1 ? "accounts remaining" : "account remaining" }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    point: {
      type: Number,
      default: null
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.b-info .balance-price {
  margin: 1rem 0 1rem 0;
  color: orange;
}
.b-info .balance-name {
  color: orange;
}
.balance-card {
  margin-bottom: 20px;
}
.bb-intro-top {
  width: 100%;
  border-top: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-left: 1px solid #ccd0d7;
  border-bottom: 1px solid #e2e9ed;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background: #eef2f4;
  padding: 12px 28px;
  min-height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.b-info {
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-bottom: 1px solid #ccd0d7;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 0 28px;
}
.b-info .info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 18px;
  /* color: #99a2aa; */
  letter-spacing: 0;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
}
@media screen and (max-width: 400px) {
  .b-info {
    padding: 0 15px;
  }
}
</style>
