<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <!-- <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item> -->
        <el-breadcrumb-item>
          Info
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <small> View {{ myTitle }} </small>
    <hr />
    <el-form label-width="140px" v-if="company">
      <el-form-item label="Name" prop="title">
        <b class="text-success">
          {{ myTitle }}
        </b>
      </el-form-item>
      <!-- <el-form-item label="Introduction" prop="title">
        <b>
          {{ company.introduction }}
        </b>
      </el-form-item> -->
      <!-- <el-form-item label="Admin" prop="title">
        <b>
          {{ company.introduction }}
        </b>
      </el-form-item> -->
      <el-form-item label="Teachers" prop="title">
        <router-link :to="{ name: 'CompanyUsers' }">
          <b>{{ company.teachers.length }}</b>
        </router-link>
      </el-form-item>
      <!-- <el-form-item label="Students" prop="title">
        <router-link :to="{ name: 'CompanyUsers' }">
          <b>{{ company.users_count - company.teachers.length }}</b>
        </router-link>
      </el-form-item> -->
      <!-- <el-form-item label="Balance" prop="title">
        <b class="text-success">
          {{ company.coin }}
          <span class="balance-name" v-if="company.coin > 0">
            {{ company.coin > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
          </span>
        </b>
      </el-form-item>
      <el-form-item
        label="Invite Teachers"
        prop="student_users_count"
        v-if="false"
      >
        <b class="text-success mr-2">
          {{ company.invitation_code }}
        </b>
        <el-tooltip
          class="mr-2"
          effect="dark"
          :content="$t('TeacherCourses.Invitation Link')"
          placement="top"
        >
          <el-button
            type="success"
            size="mini"
            @click="setLink(company.invitation_code)"
          >
            <i class="fas fa-user-plus"></i>
          </el-button>
        </el-tooltip>
      </el-form-item> -->
    </el-form>
    <div class="row">
      <div class="col-sm-6">
        <Balance title="Coins for Students" :point="company.coin" />
      </div>
      <div class="col-sm-6">
        <Pro
          title="Annual Pro Accounts for Students"
          :point="company.pro_quantity"
        />
      </div>
    </div>
    <UserTransactions :isCompanyAdmin="true"></UserTransactions>
    <el-dialog
      class="class-link"
      title="Invitation Link"
      :visible.sync="showLink"
      width="50%"
    >
      <p>Copy and send this link to teachers</p>
      <div class="text-center">
        <div class="referral-code">
          <a>
            <span>
              {{ link }}
            </span>
          </a>
          <el-button class="ml-3" type="success" @click="copyLink">
            <i class="fas fa-copy"></i>
            {{ $t("toefl.Copy Link") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Company from "@/apis/company";
import baseDomainPath from "@/common/baseDomainPath";
import { user, instant } from "@ivy-way/material";
import UserTransactions from "@/components/balance/UserTransactions.vue";
import Balance from "@/components/balance/Balance.vue";
import Pro from "@/components/balance/Pro.vue";

export default {
  metaInfo() {
    return {
      title: `Company ${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb,
    Balance,
    Pro,
    UserTransactions
  },

  mixins: [],

  props: [],
  data() {
    return {
      showLink: false,
      company: null,
      link: "",
      transactions: null
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    myTitle() {
      return this.company ? this.company.name : " ";
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {
    this.getCompanyInfo();
  },

  methods: {
    async getCompanyInfo() {
      const res = await Company.getCompanyInfo();
      this.company = res.company;
    },

    setLink(code) {
      this.linkType = "Class";
      this.link = `${baseDomainPath}company/join?code=${code}`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
</style>
