<template>
  <div
    class="balance-card"
    v-if="transactions !== undefined && transactions.length > 0"
  >
    <div>
      <div class="bb-intro-top">
        <i class="fas fa-list"></i>
        <b class="b-icon-title">
          {{ $t("sat.balance.Transactions") }}
        </b>
      </div>
      <div class="b-info">
        <div class="text-center mt-3">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <el-table :data="transactions" style="width: 100%;margin-bottom:20px">
          <el-table-column :label="$t('sat.balance.Date')">
            <template slot-scope="scope">
              <i class="el-icon-date"></i>
              <span style="margin-left: 10px">
                {{ instant.formatGoogleTime(scope.row.updated_at) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$t('sat.balance.Notes')">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.category === 'INCOME'">{{
                reason[scope.row.reason]
              }}</el-tag>
              <el-tag v-if="scope.row.category === 'EXPENSE'" type="danger">{{
                reason[scope.row.reason]
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="point" label="COINS" width="130">
            <template slot-scope="scope">
              <div v-if="scope.row.category === 'INCOME'" class="text-right">
                <h6
                  class="balance-price  text-right"
                  style="font-size:20px;margin:0"
                >
                  +{{ scope.row.coins }}
                  <small class="balance-name">
                    {{ $t("sat.Coins") }}
                  </small>
                </h6>
              </div>
              <div v-if="scope.row.category === 'EXPENSE'" class="text-right">
                <h6
                  class="balance-price text-danger"
                  style="font-size:20px;margin:0"
                >
                  -{{ scope.row.coins }}
                  <small class="balance-name text-danger">
                    {{ $t("sat.Coins") }}
                  </small>
                </h6>
              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column
            prop="description"
            :label="$t('sat.balance.Notes')"
            width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.description }}
            </template>
          </el-table-column> -->
        </el-table>
        <div class="text-center mb-3">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Balance from "@/apis/balance.js";
import Company from "@/apis/company";
import { instant } from "@ivy-way/material";

export default {
  props: ["userId", "companyId", "isCompanyAdmin"],
  data() {
    return {
      transactions: [],
      reason: {
        system_retrieval: this.$t("sat.balance.Deposit from Admin"),
        bestowing: this.$t("sat.balance.Deposit from Admin"),
        deduct: this.$t("sat.balance.Deduction from Admin"),
        reset: this.$t("sat.balance.Deduction from Admin"),
        verified_email: this.$t("sat.balance.Email Verification"),
        unlock_exam: this.$t("sat.balance.Test"),
        webhook_stripe: this.$t("sat.balance.Purchase"),
        referral: this.$t("sat.balance.Referral Bonus"),
        teacher_unlock_exam: "Student Test",
        company_bestowing: this.$t("sat.balance.Deposit from Admin"),
        company_unlock_exam: "Student Test"
      },
      resultsTotal: 0,
      page: 1,
      pageSize: 10
    };
  },
  computed: {
    instant() {
      return instant;
    }
  },
  watch: {
    userId() {
      if (this.userId) {
        this.getTransactions();
      }
    },
    isCompanyAdmin() {
      if (this.isCompanyAdmin) {
        this.getCompanyTransactions();
      }
    },
    companyId() {
      if (this.companyId) {
        this.adminGetCompanyTransactions();
      }
    }
  },
  mounted() {
    if (this.userId) {
      this.getTransactions();
    }
    if (this.isCompanyAdmin) {
      this.getCompanyTransactions();
    }
    if (this.companyId) {
      this.adminGetCompanyTransactions();
    }
  },

  methods: {
    async getCompanyTransactions() {
      const res = await Company.getCompanyTransactions({
        page: this.page,
        per_page: this.pageSize
      });
      this.transactions = res.coin_transactions;
    },
    async adminGetCompanyTransactions() {
      console.log(1);
      const res = await Company.adminGetCompanyTransactions(this.companyId, {
        page: this.page,
        per_page: this.pageSize
      });
      this.transactions = res.coin_transactions.data;
      this.resultsTotal = res.coin_transactions.total;
    },
    async getTransactions() {
      const res = await Balance.getTransactions(this.userId, {
        page: this.page,
        per_page: this.pageSize
      });
      this.transactions = res.coin_transactions.data;
      this.resultsTotal = res.coin_transactions.total;
    },
    setPage(page) {
      this.page = page;
      if (this.userId) {
        this.getTransactions();
      }
      if (this.isCompanyAdmin) {
        this.getCompanyTransactions();
      }
      if (this.companyId) {
        this.adminGetCompanyTransactions();
      }
    }
  }
};
</script>

<style scoped>
.b-info .balance-price {
  margin-left: 10px;
  color: #757575;
}
.b-info .balance-name {
  color: #757575;
}
.balance-card {
  margin-bottom: 20px;
}
.bb-intro-top {
  width: 100%;
  border-top: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-left: 1px solid #ccd0d7;
  border-bottom: 1px solid #e2e9ed;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background: #eef2f4;
  padding: 12px 28px;
  min-height: 46px;
}
.b-info {
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-bottom: 1px solid #ccd0d7;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 0 28px;
}
.b-info .info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 14px;
  color: #99a2aa;
  letter-spacing: 0;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
  color: var(--themeColor);
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  /* margin-left: 4px; */
  color: var(--themeColor);
}
@media screen and (max-width: 400px) {
  .b-info {
    padding: 0 15px;
  }
}
</style>
